var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',{scopedSlots:_vm._u([{key:"appbar",fn:function(){return [_c('v-container',{staticClass:"py-0 px-0"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-fade-transition',[(_vm.isNotFormPrestine)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('v-toolbar-title',{staticClass:"muted--text",domProps:{"textContent":_vm._s('Unsaved changes')}})],1):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-3 mr-0",attrs:{"text":"","large":""},domProps:{"textContent":_vm._s('Discard')},on:{"click":_vm.askUserToDiscardUnsavedChanges}}),_c('v-badge',{staticClass:"dt-badge",attrs:{"bordered":"","bottom":"","color":"dark","content":"s","offset-x":"20","offset-y":"20","tile":"","transition":"fade-transition"},model:{value:(_vm.shortkeyCtrlIsPressed),callback:function ($$v) {_vm.shortkeyCtrlIsPressed=$$v},expression:"shortkeyCtrlIsPressed"}},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 's']),expression:"['ctrl', 's']",modifiers:{"once":true}}],ref:"submit-button-main",staticClass:"ml-3 mr-0",attrs:{"disabled":_vm.isFormDisabled,"loading":_vm.isLoading,"color":"primary","large":"","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save-outline")]),_c('span',{domProps:{"textContent":_vm._s('Save')}})],1)],1)],1)])],1)],1)]},proxy:true}])},[_c('metatag',{attrs:{"title":'Add User'}}),_c('validation-observer',{ref:"addform",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var invalid = ref.invalid;
var passed = ref.passed;
return [_c('v-form',{ref:"addform-form",attrs:{"disabled":_vm.isLoading,"autocomplete":"false","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('button',{ref:"submit-button",staticClass:"d-none",attrs:{"type":"submit"}}),_c('page-header',{attrs:{"back":{ to: { name: 'users.index' }, text: _vm.trans('Users') }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Add User'))}})]},proxy:true}],null,true)}),_c('alertbox'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.trans('Account Information'))}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.trans('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"dt-text-field",attrs:{"dense":_vm.isDense,"disabled":_vm.isLoading,"error-messages":errors,"label":_vm.trans('Name'),"autofocus":"","name":"name","outlined":"","prepend-inner-icon":"mdi-account-outline"},model:{value:(_vm.resource.data.name),callback:function ($$v) {_vm.$set(_vm.resource.data, "name", $$v)},expression:"resource.data.name"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }